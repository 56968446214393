import {defineStore} from "pinia";
import type {User} from "~/types/models";
import {useCartStore} from "~/stores/cart";

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            user: null as User | null,
            token: null as string | null,
            cookies: false as boolean,
        };
    },
    actions: {
        logout() {
            this.token = null;
            this.user = null;
            useCartStore().emptyCart();
        },
        setToken(token: string) {
            this.token = token;
        },
        setUser(user: User) {
            this.user = user;
        },
        setCookies(value: boolean){
            this.cookies = value;
        }
    },
    getters: {
        isAuthenticated(): boolean {
            return !!this.token;
        },
        getUser(): User | null {
            return this.user;
        },
        getToken(): string | null {
            return this.token;
        },
        getCookies(): boolean {
            return this.cookies;
        }
    },
    persist: true,
});