import { defineStore } from "pinia";
interface Notification {
    message: string;
    type: string;
    duration: number;
}
export const useNotificationStore = defineStore("notifications", {
    state: () => {
        return {
            notifications: [] as Notification[],
        };
    },
    actions: {
        addNotification(notification: Notification) {
            this.notifications.push(notification);
        },
        clearNotifications() {
            this.notifications = [];
        },
        removeNotification(message: string) {
            this.notifications = this.notifications.filter(
                (notification) => notification.message !== message
            );
        }
    },
});