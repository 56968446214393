import {defineStore} from "pinia";
import {useUserStore} from "~/stores/user";
import type {CartResponse} from "~/types/responses";
import type {CartProductPackage} from "~/types/models";

export const useCartStore = defineStore("cart", {
    state: () => {
        return {
            cart: [] as CartProductPackage[],
        };
    },
    actions: {
        emptyCart() {
            this.cart = [];
        },
        async fetchCart() {
            $api('/user/cart').then((response: CartResponse) => {
                this.cart = response.data;
            }).catch((error) => {
                this.cart = [];
            })
        },
    },
    getters: {
        containsPackage: (state) => {
            return (packageId: string) => state.cart.find((cardItem) => cardItem.package.id === packageId) !== undefined;
        },
        containsProduct: (state) => {
            return (productId: string) => state.cart.find((cardItem) => cardItem.package.product.id === productId) !== undefined;
        },
        count(): number {
            return this.cart.length;
        },
        total(): number {
            let result = 0;
            this.cart.forEach((cartItem) => {
                result += cartItem.price;
            });
            return result;
        },
        getCart(): any {
            return this.cart;
        },
    },
});